/**
 * jQuery content slideshow
 * @name showslide
 * @author Dominik Kressler
 * @version 1.1.3
 * @category jQuery plugin
 * @copyright (c) 2013
 *
 * Changelog:
 *
 * SEE JS FILE
*/

%controls-pseudo {
    font-family: $icon-font;
    text-indent: 0;
    display: block;
    color: $light;
    font-size: rem(24px);
    text-align: center;
    line-height: 2rem;
}


/**
 * NAVIGATION
 */
.showslideController {
    cursor: pointer;
    list-style: none;
    z-index: 20;
    transition: all 500ms ease-in-out;
}

a.showslideController {
    background-color: rgba($dark, .2);
    border-radius: rem(4px);
    display: block;
    height: rem(30px);
    position: absolute;
    @include hide-text();
    padding:rem(3px);
    opacity: .6;
    width: rem(30px);

    &.showslidePlay {
        bottom: rem($base-gap);
        left: 50%;
        margin-left: rem(-15px);

        &:before {
            @extend .fa-pause;
            @extend %controls-pseudo;
        }

        &.true:before {
            @extend .fa-play;
            @extend %controls-pseudo;
        }
    }

    &.showslidePrev, &.showslideNext {
        top: 50%;
        margin-top: rem(-15px);
    }

    &.showslidePrev {
        left: rem($base-gap);

        &:before {
            @extend %controls-pseudo;
            @extend .fa-chevron-left;
        }
    }

    &.showslideNext {
        right: rem($base-gap);

        &:before {
            @extend %controls-pseudo;
            @extend .fa-chevron-right;
        }
    }

    &:hover {
        background-color: rgba($dark, .8);
        opacity:1;
    }
}

.showslideSelect {
    z-index: 20;
    position: absolute;
    bottom: rem($base-gap / 2);
    margin:auto;
    text-align: center;
    width:100%;

    li {
        display: inline-block;
        @include hide-text();
        width: rem(14px);
        height: rem(14px);
        border-radius: 50%;
        background: $light;
        opacity: 0.5;
        margin-right: rem(15px);
    }
    &.thumbnails li {
        width: rem(64px);
        height: rem(64px);
        background-size: cover;
        border: 3px solid $primary;
    }
    li {
        &.active, &:hover {
            opacity: 1;
        }
    }
}

/**
* GLOBALS
**/

.slider {
    overflow: hidden;
    position: relative;
    width: 100%;

    /**
     * SPEED SETTING
     */

    > .animate {
        transition-duration: 1000ms;
    }

    /**
    * SPECIAL EASINGS
    * FOR MORE EASINGS: http://matthewlein.com/ceaser/
    **/
    &.horizontal > .animate {
        transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
        /* easeInOutQuad */
    }
    &.vertical > .animate {
        transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
        /* easeInOutCirc */
    }
    &.fade > .animate {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        /* easeOutCubic */
    }
    &.flip > .animate {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        /* easeOutCubic */
    }
    &.puffOut > .animate {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        /* easeOutCubic */
    }
    &.puffIn > .animate {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        /* easeOutCubic */
    }
    &.zoomOut > .animate {
        transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
        /* easeInOutCirc */
    }
    &.zoomIn > .animate {
        transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
        /* easeInOutCirc */
    }

    .slide {
        z-index: 1;

        &:not(.active) {
            z-index: 1;
        }
        &.active {
            float: left;
            position: relative;
            z-index: 10;
        }

        img {
            height:auto;
            width:100%;
        }

        width: 100%;
        height: 100%;
        display: block;
        position: absolute;

        /**
        * ELEMENTS WITH data-caption-ATTRIBUTES
        **/
        &[data-caption]:after {
            content: attr(data-caption);
            background: rgba(255, 255, 255, 0.5);
            left: 0;
            color: #000;
            display: block;
            padding: 1em;
            position: absolute;
            width: 100%;
            top: -100%;
        }
        &.active[data-caption]:after {
            top: 0;
        }
        &[data-caption]:after {
            transition-property: all;
        }
    }

    /**
    * TRANSITIONS
    **/
    &.fade > .animate, &.horizontal > .animate, &.vertical > .animate, &.zoomOut > .animate, &.zoomIn > .animate, &.puffOut > .animate, &.puffIn > .animate {
        transition-property: all;
    }

    /**
    * FADE
    **/
    &.fade .slide {
        opacity: 0;
        &.active {
            opacity: 1;
        }
    }

    /**
    * HORIZONTAL
    **/
    &.horizontal .slide {
        &.active {
            left: 0;
            z-index: 10;
        }
        &.right {
            left: 100%;
            z-index: 1;
        }
        &.left {
            left: -100%;
            z-index: 1;
        }
    }

    /**
     * VERTICAL
     */
    &.vertical {
        .active {
            top: 0;
            z-index: 10;
        }
        .left {
            top: -100%;
            z-index: 1;
        }
        .right {
            top: 100%;
            z-index: 1;
        }
    }

    /**
     * FLIP
     */
    &.flip {
        > .animate {
            transition: all 2000ms ease-in-out;
        }
        overflow: visible;
        .left {
            opacity: 0;
            transform: perspective(800px) rotateX(-180deg) translateZ(300px);
        }
        .right {
            opacity: 0;
            transform: perspective(800px) rotateX(180deg) translateZ(300px);
        }
        .active {
            opacity: 1;
            transform: perspective(0) rotateX(0) translateZ(0);
        }
    }

    /**
     * puffOUT
     */
    &.puffOut {
        overflow: visible;
        .left {
            opacity: 0;
            transform: scale(1.5);
        }
        .right {
            opacity: 0;
            transform: scale(0.5);
        }
        .active {
            opacity: 1;
            transform: scale(1);
        }
    }

    /**
     * puffIN
     */
    &.puffIn {
        overflow: visible;
        .left {
            opacity: 0;
            transform: scale(0.5);
        }
        .right {
            opacity: 0;
            transform: scale(1.5);
        }
        .active {
            opacity: 1;
            transform: scale(1);
        }
    }

    /**
     * zoomOUT
     */
    &.zoomOut {
        .left {
            opacity: 0;
            transform: scale(0.2);
        }
        .right {
            opacity: 0;
            transform: scale(1.5);
        }
        .active {
            opacity: 1;
            transform: scale(1);
        }
    }

    /**
     * zoomIN
     */
    &.zoomIn {
        .left {
            opacity: 0;
            transform: scale(1.5);
        }
        .right {
            opacity: 0;
            transform: scale(1.5);
        }
        .active {
            opacity: 1;
            transform: scale(1);
        }
    }
}
/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: calc(70vh);
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}
// SLIDESHOW
//////////////////////////////
@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\f04d" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
    @else {
        @return url($slick-font-path + $url);
    }
}

/* Icons */
@if $slick-font-family == "slick" {
    @font-face {
        font-family: "slick";
        src: slick-font-url("slick.eot");
        src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
    }
}

/* Arrows */

.slick-prev,
.slick-next {
    display: none !important;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
}

.slick-prev {
    display:none;
}

.slick-next {
    display: none;
    [dir="rtl"] & {
        left: -25px;
        right: auto;
    }
    &:before {
        content: $slick-next-character;
        [dir="rtl"] & {
            content: $slick-prev-character;
        }
    }
}

/* Dots */

.slick-dotted.slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: 1.5rem;
    left: 0rem;
    list-style: none;
    display: none !important;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;    
    z-index: 99;
    li {
        position: relative;
        display: inline-block;
        padding: 0;
        cursor: pointer;
        transition: all ease-in-out 300ms;
        button {
            border: 0;
            background: transparent;
            display: block;
            height: 20px;
            width: 20px;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            margin: 0rem 0.05rem;
            transition: all ease-in-out 300ms;
            &:hover, &:focus {
                outline: none;
                &:before {
                    opacity: $slick-opacity-on-hover;
                }
            }
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: $slick-dot-character;
                color: $light;
                font-family: $icon-font;
                font-size: 0.8rem;
                line-height: 20px;
                opacity: 0.7;
                text-align: center;
                opacity: $slick-opacity-not-active;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }
        &.slick-active button:before {
            color: $light;
            transform: scale(1.2,1.5);
            padding-bottom: 0.8rem;
            opacity: 1.0;
        }
    }
    @include breakpoint(medium){
        display: block !important;
        li {
            position: relative;
            display: inline-block;
            padding: 0;
            cursor: pointer;
            transition: all ease-in-out 300ms;
            button {
                border: 0;
                background: transparent;
                display: block;
                height: 20px;
                width: 20px;
                outline: none;
                line-height: 0px;
                font-size: 0px;
                color: transparent;
                padding: 5px;
                cursor: pointer;
                transition: all ease-in-out 300ms;
                margin: 0rem 0.15rem;
                &:hover, &:focus {
                    outline: none;
                    &:before {
                        opacity: $slick-opacity-on-hover;
                    }
                }
                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: $slick-dot-character;
                    color: $light;
                    font-family: $icon-font;
                    font-size: 1rem;
                    line-height: 20px;
                    opacity: 0.7;
                    text-align: center;
                    opacity: $slick-opacity-not-active;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    transition: all ease-in-out 300ms;
                }
            }
            &.slick-active button:before {
                color: $light;
                transform:scale(1.2,1.5);
                opacity: 1.0;
            }
        }
    }
}