// Icons
@font-face {
	font-family: "FontAwesome";
	font-weight: 400;
	font-style: normal;
	font-display: block;
	src: url("/css/fonts/fontawesome-webfont.woff") format("woff"), url("/css/fonts/fontawesome-webfont.ttf") format("truetype"), url("/css/fonts/fontawesome-webfont.svg#Spartan") format("svg"), url("/css/fonts/fontawesome-webfont.eot?#iefix") format("embedded-opentype")
}

@font-face {
	font-family: "Roboto";
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	src: url("/css/fonts/roboto-v27-latin-regular.ttf") format("truetype"),
}

@font-face {
	font-family: "Saira Extra Condensed";
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	src: url("/css/fonts/saira-extra-condensed-v6-latin-regular.ttf") format("truetype"),
}