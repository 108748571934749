// Main Navigation
//////////////////////////////

.navi-main {
	li {
		font-size: rem(22px);
		color: $cast;
		padding: 1.2rem 0;
		border-bottom: 1px solid $cast;

		&:last-child{
			border: none;
		}

		&:hover {
			a {

			}
		}

		&.active {
			a {

			}
		}

		a {
			text-decoration: none;
			color: $secondary;
			opacity: 1;
		}
	}
	@include breakpoint(large){
	    display: flex;
	    justify-content: space-between;
   		padding: 3rem 2rem 0;
		li {
			display: inline-block;
			font-size: rem(17px);
			color: $light;
			padding: 1.2rem 0;
			border: none;
			list-style: outside none none;

			&:last-child{
				border: none;
			}
			&:hover {
				a {
				    &:before, &:after, &:focus{
				    	opacity: 1;
						-webkit-transform: translateX(0px);
						-moz-transform: translateX(0px);
						transform: translateX(0px);
				    }
				}
			}

			&.active {
				a {
					&:before, &:after, &:focus{
				    	opacity: 1;
						-webkit-transform: translateX(0px);
						-moz-transform: translateX(0px);
						transform: translateX(0px);
				    }
				}
			}

			a {
				text-decoration: none;
				color: $light;
			    display: block;
			    position: relative;
			    &:before, &:after{
				    display: inline-block;
					opacity: 0;
					-webkit-transition: -webkit-transform 0.3s, opacity 0.2s;
					-moz-transition: -moz-transform 0.3s, opacity 0.2s;
					transition: transform 0.3s, opacity 0.2s;
			    }
			    &:before{
			    	margin-right: 5px;
					content: '[';
					-webkit-transform: translateX(20px);
					-moz-transform: translateX(20px);
					transform: translateX(20px);
			    }
			    &:after{
			    	margin-left: 5px;
					content: ']';
					-webkit-transform: translateX(-20px);
					-moz-transform: translateX(-20px);
					transform: translateX(-20px);
			    }
			}
		}
	}
	@include breakpoint(giant){
		li {
			display: inline-block;
			font-size: rem(22px);
			color: $light;
			padding: 1.2rem 0;
			border: none;
			list-style: outside none none;

			&:last-child{
				border: none;
			}
			&:hover {
				a {
				    &:before, &:after, &:focus{
				    	opacity: 1;
						-webkit-transform: translateX(0px);
						-moz-transform: translateX(0px);
						transform: translateX(0px);
				    }
				}
			}
		}
	}
	.sub {
		li {

		}

		a {

		}
	}
}

// Mobile Menu Bar
//////////////////////////////

$menu-bar: 50px;

.page-navi {

	// Off Canvas Navigation
	//////////////////////////////

	font-family: $main-font;
	background: $light;
	left:-100%;
	overflow-y: auto;
	transition:.6s;
	top: rem($menu-bar);
	width:map-get($breakpoints, tiny) * .8;
	z-index: 895;
    bottom: 0;
    max-width:100%;
    position: absolute;

	.contactdata{
    	background: $light;
    	.contra{
    		display: block;
	    	a{
	    		color: $secondary;
	    		text-decoration: none;
	    		display: flex;
	    		justify-content: space-around;
	    		&.email{
	    			display: none;
	    		}
	    		&.call{
	    			display: none;
	    		}
	    	}
		    dl.opening{
		    	display: none;
		    }
		    .onlineshop{
		    	background: $primary;
		    	padding: 1.2rem;
		    	color: $light;
		    	text-decoration: none;
		    	font-size: rem(20px);
		    	font-family: $main-font;
		    	&:before{
		    		content: '\f07a';
		    		font-family: $icon-font;
		    		font-size: 21px;
		    		margin-right: 0.5rem;
		    	}
		    }
	    }
	}
    
    .branding {
		background: $light;
		display: none;
		padding: 1rem 28%;
		z-index: 999;
		opacity: 1;
		@include breakpoint(tiny) {
		    padding: 1rem 38%;
		}
		@include breakpoint(small) {
		    padding: 1rem 40%;
		}
		@include breakpoint(large) {
			display: block;
		    padding: 0 3rem;
		   	height: 9.75rem;
    		width: 18rem;
		    float: left;
		    display: inline-block;
		}
	}
    
    .navcont{
    	background: $secondary;
    	padding: 2rem;
    	display: flex;
    	justify-content: space-around;
    	align-items: center;
	    .onlineshop{
	    	background: $primary;
	    	padding: 1.2rem;
	    	color: $light;
	    	text-decoration: none;
	    	font-size: rem(20px);
	    	font-family: $main-font;
	    	&:before{
	    		content: '\f07a';
	    		font-family: $icon-font;
	    		font-size: 21px;
	    		margin-right: 0.5rem;
	    	}
	    }
    }
	// Desktop Navigation
	//////////////////////////////

	@include breakpoint(large) {
	    bottom: auto;
	    left: auto;
	    width: 100%;
	    background: $secondary;
	    z-index: 201;
	    overflow: visible;
	    position: relative;
	    top: 0;

		.navcont{
			display: none;
		}
		.contactdata{
    		background: $light;
	    	.contra{
	    		display: flex;
	    		justify-content: space-between;
	    		align-items: center;
	    		padding: 0rem 1.8rem 0 18rem;
	    		@include breakpoint(large){
	    			padding: 0rem 1.8rem 0 0rem;
	    		}
	    		@include breakpoint(giant){
	    			padding: 0rem 1.8rem 0 18rem;
	    		}
		    	a{
		    		color: $secondary;
		    		text-decoration: none;
		    		display: flex;
		    		justify-content: space-around;
		    		&.email{
		    			display: inline-block;
		    			font-family: $display-font;
		    			transition: 300ms all ease-in-out;
		    			&:before{
		    				content: '\f0e0';
		    				font-family: $icon-font;
		    				font-size: rem(14px);
		    				padding: 0.4rem;
	    				    border-radius: 20px;
		    				margin-right: 0.5rem;
		    			}
		    			&:hover{
		    				opacity: 0.6;
		    			}
		    		}
		    		&.call{
		    			font-family: $display-font;
		    			display: block;
		    		}
		    	}
			    dl.opening{
			    	display: inline-flex;
			    	font-family: $display-font;
			    	font-size: rem(14px);
			    	color: $secondary;
			    	margin: 0;
			    	&:before{
			    		content: '\f017';
			    		font-family: $icon-font;
			    		font-size: 21px;
			    		margin-right: 0.5rem;
			    	}
			    	dt{
			    		margin-right: 0.5rem;
			    	}
			    }
			    .onlineshop{
			    	background: $primary;
			    	padding: 1.2rem;
			    	color: $light;
			    	text-decoration: none;
			    	font-size: rem(20px);
			    	font-family: $main-font;
			    	transition: 300ms all ease-in-out;
			    	&:before{
			    		content: '\f07a';
			    		font-family: $icon-font;
			    		font-size: 21px;
			    		margin-right: 0.5rem;
			    	}
			    	&:hover{
			    		background: $light;
				    	color: $primary;
				    	transition: 300ms all ease-in-out;
			    	}
			    }
		    }
		}
	}
}

.mobile-bar {
	align-items:center;
	background:$light;
	color: $secondary;
	display: flex;
	height:rem($menu-bar);
	justify-content:stretch;
	left:0;
	position: fixed;
	font-family: $display-font;
	right:0;
	top:0;
	z-index: 894;

	li {
		display:flex;
		flex-grow:1;
		padding:0 rem($base-gap);

		&:last-child {
            justify-content:flex-end;
        }
	}

	@include breakpoint(large) {
		display: none;
	}
}

label[for="navi-toggled"] {
	@extend .fa-bars;
	flex-direction:row-reverse;
	font-size: rem(18px);

	&:before {
		@extend .fa-default;
		margin-right: rem(10px);
	}

	&:after {
		background:rgba($dark,0.8);
		bottom:0;
		content:'';
		left:0;
		opacity: 0;
		pointer-events:none;
		position: fixed;
		top:rem($menu-bar);
		transition:opacity .6s;
		width: 100%;
		z-index:-1;
	}
}

#navi-toggled {
	display: none;

	&:checked {

		~ * label[for="navi-toggled"] {
			@extend .fa-close;

			&:after {
				opacity: 1;
				pointer-events:auto;
			}
		}

		~ * .page-navi,
		~ .page-navi {
			left:0;
		}
	}
}