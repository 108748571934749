// Styled list
dl.floatList {
	@include pie-clearfix;

	dt, dd {
		display: block;
		vertical-align: top;
		float: left;
	}

	dt {
		padding-right: .5rem;
		width: 40%;
		clear:left;
	}

	dd {
		width: 60%;
	}
}

// SOME HELPFUL CLASSES
//////////////////////////////

.highlight {
	color: $secondary;
}

.btn {
	background: $medium;
	border: none;
	color: $dark;
	cursor: pointer;
	display: table;
	font-size: 1rem;
	line-height: 1rem;
	padding: .7rem 1rem;
	text-align: center;
	text-decoration: none;
	transition: 300ms all ease-in-out;

	&:hover, &:active, &:focus {
		background: $dark;
		color: $light;
	}

	*[class^="icon"] {
		margin-left: .5rem;
		vertical-align: middle;
	}
}

.notification {
	padding: 1em;
	background: $alert;
	color: $light;
	font-size: 1.2em;
}

// PAGE WRAP
//////////////////////////////

.page-wrap {
	padding-top:rem($menu-bar);
    overflow: hidden;
	
	@include breakpoint(medium) {
		padding-top:0;
	}
}

// HEADER
//////////////////////////////

.header {
	padding: 0;
	@include breakpoint(large){
		padding: 2rem 0;
	}
	a.branding {
        position: relative;
	    background: $light;
	    padding: 1rem 1rem;
        display: flex;
    	justify-content: center;
    	&:hover{
    		opacity: 1;
    	}
	    img{
    	    max-height: 11rem;
    		width: auto;
	    }
	    @include breakpoint(medium){
	    	padding-top: 4rem;
	    }
	    @include breakpoint(large){
	    	display: none;
	    }
	}
	.slider{
		display: none;
	}
	.infoca{
		background: $light;
	}
	.werbeslogan{
	    display: none;
	}
	.usp{
		font-family: $main-font;
		color: $light;
		font-size: rem(40px);
		line-height: rem(20px);
		text-shadow: 2px 2px 5px $dark;
		strong{
			line-height: rem(40px);
		}
		span{
			font-size: rem(20px);
		}
		@include breakpoint(medium) {
		    font-size: rem(70px);
		    line-height: rem(58px);
		    span{
				font-size: rem(50px);
			}
		}
	}
	.fb{
		position: absolute;
	    right: 0;
	    background: url(/images/facebook.png) no-repeat center $primary;
	    padding: 2rem;
	    top: 5rem;
	    z-index: 999;
		@include breakpoint(medium){
		    position: fixed;
		    top: 27rem;
		    &:hover{
		    	opacity: 0.8;
		    	background: url(/images/facebook.png) no-repeat center $secondary;
		    }
	    }
    }
	.contact{
		background: $primary;
		margin: 1.5rem 0;
		padding: 0.7rem 0.0rem 0.8rem 0.8rem;
		color: $light;
		font-family: $display-font;
		font-size: rem(13px);
		opacity: 1;
		&:after{
		    content: '\f105';
		    font-family: "FontAwesome";
		    font-size: .625rem;
		    padding: 0.9rem;
		    background: rgba(255, 255, 255, 0.4);
		    margin-left: 0.8rem;
		}
		@include breakpoint(medium) {
		    font-size: rem(16px);
		    padding: 0.7rem 0.0rem 0.8rem 2.8rem;
		    &:after{
			    font-size: 1.2rem;
			    padding: 0.6rem 0.9rem 0.85rem;
			    margin-left: 2.8rem;
			    transition: 300ms ease-in-out all;
			}
			&:hover{
				&:after{
					margin-left: 3.8rem;
					transition: 300ms ease-in-out all;
				}
			}
		}
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;
	&.content{
		padding: 7.5rem 0 0;
		@include breakpoint(medium){
			padding: 3rem 0;
		}
		.product{
			position: relative;
			background: $primary;
			padding-top: 3rem;
   			padding-bottom: 3rem;			
   			ul{
				li{
				    padding-top: .3rem;
					padding-bottom: .3rem;
					padding-left: 1.4375rem;
					&:before{
						content:'\f04d';
						font-family: $icon-font;
						font-size: rem(12px);
					    line-height: 1.5rem;
					    margin-right: .67rem;
					    margin-left: -1.4375rem;
					}
				}
			}
			.prodbtn{
				background: $secondary;
				margin: 1.5rem 0;
				padding-left: 0.8rem;
				color: $light;
				font-family: $display-font;
				font-size: rem(16px);
			    display: flex;
				justify-content: space-between;
				align-items: center;
				transition: 300ms ease-in-out all;
				&:after{
				    content: '\f105';
				    font-family: $icon-font;
				    font-size: 1rem;
				    padding: 0.56rem 0.9rem;
				    background: rgba(255, 255, 255, 0.4);
				    margin-left: 0.8rem;
				}
				@include breakpoint(small){
					width: 13.2rem;
				}
				@include breakpoint(medium){
					&:hover{
						width: 14.2rem;
						opacity: 1;
					}
				}
			}
		}
		@include breakpoint(medium) {
			.product{
    	        position: absolute;
			    padding: 4.5rem 0rem 4.5rem 9rem;
			    margin-top: 2rem;
			    right: 0;
			    margin-left: 2rem;
			}
		}
		@include breakpoint(large) {
			.product{
    	        position: absolute;
			    padding: 4.5rem 0rem 4.5rem 11rem;
			    margin-top: 2rem;
			    right: 0;
			    margin-left: 2rem;
			}
		}
		@include breakpoint(giant) {
			.product{
    	        position: absolute;
			    padding: 4.5rem 0rem 4.5rem 13.5rem;
			    margin-top: 2rem;
			    right: 0;
			    margin-left: 2rem;
			}
		}
		.stroke{
			margin-bottom: 2rem;
			padding-left: 0rem;
			&:before{
				content: '';
				position: absolute;
				border: 5px solid $primary;
		        padding: 5.2rem 8rem;
			    margin: 1rem;
			    z-index: -1;
			}
			@include breakpoint(tiny) {
	    	    padding-right: 1.5rem;
	    	    &:before{
	    	    	padding: 8rem 12.2rem;
	    	    }
			}
			@include breakpoint(small) {
			    &:before{
			    	padding: 11.5rem 17.5rem;
				}
			}
			@include breakpoint(medium) {
				&:before{
			    	padding: 5rem 7.6rem;
				}
			}
			@include breakpoint(large) {
				&:before{
			    	padding: 6.5rem 10rem;
				}
			}
			@include breakpoint(giant) {
				&:before{
			    	padding: 8.5rem 12.9rem;
				}
			}
		}
		.ance{
			padding: 3rem 0;
			@include breakpoint(medium){
				padding: 5rem 0;
			}
		}
		ul{
			li{
			    padding-top: .3rem;
				padding-bottom: .3rem;
				padding-left: 1.4375rem;
				&:before{
					content:'\f04d';
					font-family: $icon-font;
					font-size: rem(12px);
				    line-height: 1.5rem;
				    margin-right: .67rem;
				    margin-left: -1.4375rem;
				}
			}
		}
		.spezialist{
			background: $primary;
			padding: 5rem 0;
			a{
				color: $secondary;
			}
		}
		.referenz{
			height: calc(50vh);
			position: relative;
			margin-bottom: 2.5rem;
			overflow: hidden;
			z-index: 9;
			@include breakpoint(medium){
				height: calc(60vh);
			}
			@include breakpoint(giant){
				height: calc(50vh);
			}
			.slick-dots {
			    position: absolute;
		        bottom: 3.5rem;
			    left: 5.5rem;
			    list-style: none;
			    display: block;
			    text-align: center;
			    padding: 0;
			    margin: 0;
			    width: 100%;
			    z-index: 999;
			    @include breakpoint(tiny){
		    	    bottom: 2.5rem;
    				left: 10.5rem;
			    }
			    @include breakpoint(small){
	    	        bottom: 2.5rem;
					left: 19.5rem;
			    }
			    @include breakpoint(medium){
			    	bottom: 2.5rem;
    				left: 10.5rem;
			    }
			    @include breakpoint(large){
			    	left: 13.5rem;
			    }
			    @include breakpoint(giant){
			    	left: 17rem;
			    }
			    li {
		            position: relative;
		            display: inline-block;
		            padding: 0;
		            cursor: pointer;
		            transition: all ease-in-out 300ms;
		            &:before{
		            	display: none;
		            }
		            button {
		                border: 0;
		                background: transparent;
		                display: block;
		                height: 20px;
		                width: 20px;
		                outline: none;
		                line-height: 0px;
		                font-size: 0px;
		                color: transparent;
		                padding: 5px;
		                cursor: pointer;
		                transition: all ease-in-out 300ms;
		                margin: 0rem 0rem;
		                &:hover, &:focus {
		                    outline: none;
		                    &:before {
		                        opacity: $slick-opacity-on-hover;
		                    }
		                }
		                &:before {
		                    position: absolute;
		                    top: 0;
		                    left: 0;
		                    content: $slick-dot-character;
		                    color: $light;
		                    font-family: $icon-font;
		                    font-size: 0.8rem;
		                    line-height: 20px;
		                    opacity: 0.7;
		                    text-align: center;
		                    opacity: $slick-opacity-not-active;
		                    -webkit-font-smoothing: antialiased;
		                    -moz-osx-font-smoothing: grayscale;
		                    transition: all ease-in-out 300ms;
		                }
		            }
		            &.slick-active button:before {
		                color: $light;
		                opacity: 1.0;
		                transform: scale(1);
		            }
        		}
			}
			div{
				&.first{
					background: url('/images/referenzen.jpg') no-repeat top center/cover;
				}
				&.second{
					background: url('/images/referenzen.jpg') no-repeat top center/cover;
				}
				blockquote{
					background: $secondary;
					padding: 1.5rem;
					margin: 7.5rem 1.5rem 1.5rem;
					position: relative;
					h3{

					}
					p{

					}
				}
				@include breakpoint(tiny){
					blockquote{
						background: $secondary;
						padding: 1.5rem;
						margin: 12.5rem 6.5rem 1.5rem;
						position: relative;
						width: 20rem;
	    				height: 10rem;
						h3{

						}
						p{

						}
					}
				}
				@include breakpoint(small){
					blockquote{
						background: $secondary;
						padding: 1.5rem;
						margin: 20.5rem 24.5rem 1.5rem;
						position: relative;
						width: 20rem;
	    				height: 10rem;
						h3{

						}
						p{

						}
					}
				}
				@include breakpoint(medium){
					blockquote{
						background: $secondary;
						padding: 1.5rem;
						margin: 17.5rem 1.5rem 1.5rem;
						position: relative;
						width: 24rem;
	    				height: 10rem;
						h3{

						}
						p{

						}
					}
				}
				@include breakpoint (large){
					blockquote{
						background: $secondary;
						padding: 1.5rem;
						margin: 17.5rem 8.5rem 1.5rem;
						position: relative;
						width: 24rem;
	    				height: 10rem;
						h3{

						}
						p{

						}
					}
				}
				@include breakpoint (giant){
					blockquote{
						background: $secondary;
						padding: 1.5rem;
						margin: 17.5rem 16rem 1.5rem;
						position: relative;
						width: 24rem;
	    				height: 10rem;
						h3{

						}
						p{

						}
					}
				}
			}
		}
	}	

	ul {
		@extend .styled-list;
	}
	.google-maps {
		@extend .video-container;

		@include breakpoint(medium) {
			height: rem(400px);
		}
	}
}

// FOOTER
//////////////////////////////

.footer {
	display: block;
	background: $light;
	color: $secondary;

	.footerdat{
		padding: 2rem 0;

		@include breakpoint(tiny){
			padding: 2rem 0 4rem;
		}
		@include breakpoint(medium){
			padding: 4rem 0 4rem;
		}
		@include breakpoint(large){
			padding: 8rem 0 4rem;
		}
	}
	a{
		color: $secondary;
		&:hover{
			color: $secondary;
			opacity: 0.8;
		}
	}
}

// optimizing
//////////////////////////////

body.index{
	.content{
		padding: 7.5rem 0rem 0rem;
		@include breakpoint(medium){
			padding: 7.5rem 0rem 0rem;
		}
	}
	.header{
		padding: 0rem 0;
		.slider{
			position: absolute;
			display: block;
			height: calc(60vh);
			.first{
				background: url('/images/slider/pottendorfer-schaltungsspezialist.jpg') no-repeat top center/cover;
			}
			.second{
				background: url('/images/slider/unsere-produkte.jpg') no-repeat top center/cover;
			}
		}
		.werbeslogan{
		    padding: 7.5rem 0 8.5rem;
		    position: relative;
	        display: table;
	        @include breakpoint(tiny) {
			    padding: 8.5rem 0 9.5rem;
			}
	        @include breakpoint(small) {
			    padding: 10.5rem 0 8.5rem;
			}
		    @include breakpoint(medium) {
			    padding: 8rem 0 8rem;
			}
			@include breakpoint(large){
				padding: 8rem 0 11rem;
			}
			@include breakpoint(giant){
				padding: 10rem 0 13rem;
			}
		}
	}
}
.blocked{
	display: inline-block;
	margin-right: 6rem;
}
body.iexplore{
	.product{
		.prodbtn{
			padding: 1rem 1rem;
			width: 14.2rem;
			&:hover{
				background: $light;
				color: $primary;
			}
			&:after{
			    content: '\f105';
			    font-family: $icon-font;
			    font-size: 1rem;
			    padding: 0.56rem 0.9rem;
			    background: rgba(255, 255, 255, 0.4);
			    margin-left: 0.8rem;
			}
		}
	}
}
.page-navi .contactdata .contra dl.opening{
	display: inline-flex;
	flex-flow: row wrap;
	justify-content: space-between;
	max-width: fit-content;
	position: relative;
	max-width: 30%;
	padding-left: 1rem;
	margin-left: 1%;
	dd{
		
		margin: 0;
	}
	dt{
		
		margin: 0;
	}
	&:before{
		position: absolute;
		left: -1.5%;
		top:50%;
		transform: translate( -5px, -50%);
	}
}